<template>
<div>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Service Fee Management</h1>
    </v-container>
</div>
</template>

<script>
export default {
    name: 'Service-Fee',
};
</script>
